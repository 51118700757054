<template>
   <div class="home">

      <div class="content">
         <!--标题-->
          <div class="title">充值到余额</div>
         <!--页卡-->
         <el-form :model="ruleForm" :rules="rule" ref="chongzhi" class="form" size="mini" >

            <div>当前账户  <span> 上海广眼物联网科技有限公司</span></div>

            <div class="margin-top-10">账户余额   <span style="size: 24px;" > ¥{{moneyRemain===''?'0.00':moneyRemain}}</span></div>

            <el-form-item  label="充值金额"  prop="moneyChange"  class="margin-top-10" size="mini"  >
               <el-input v-model="ruleForm.moneyChange" @input="moneyAddInput" placeholder="请输入充值金额"   size="mini" />
            </el-form-item>

            <div class="common-txt-gray">如果已经打款到广眼账户,财务审核后就会充值到账!</div>

            <!--对公账户-->
            <div class="common-txt-gray margin-top-10" style="padding: 10px">
               <div>对公账户 </div>
               <div class="margin-top-10 size" >开户名:上海广眼物联网科技有限公司 </div>
               <div class="size">银行账号：121936238910501 </div>
               <div class="size">开户行：招商银行股份有限公司上海花木支行 </div>
            </div>

            <div class="margin-top-10">充值方式   <span style=" color: #838484;font-weight: normal;" > 银行转账</span></div>

            <div class="margin-top-10" style="margin-bottom:10px;">打款金额
               <span style=" color: #838484;font-weight: normal;" > {{ruleForm.moneyChange===''?'0.00':ruleForm.moneyChange}}元</span></div>

            <el-form-item  label="付款账户名称"  prop="examinerName"  size="mini" class="margin-top-10" >
               <el-input v-model="ruleForm.examinerName" placeholder="您的公司名称或银行卡账户名"   size="mini" />
            </el-form-item>

            <el-form-item  label="付款账号"  prop="examinerAccount" style="margin-top: 20px;"  size="mini"  >
               <el-input v-model="ruleForm.examinerAccount" @input="examimerAccountInput" placeholder="银行卡号"   size="mini" />
            </el-form-item>

            <el-form-item  label="支付截图"    size="mini" style="margin-top: 20px;" >
               <el-upload class="upload-image"
                          list-type="picture-card"
                          :limit='1'
                          :action="uploadUrl"
                          :auto-upload="true"
                          :on-preview="srcList"
                          :on-remove='imgBroadcastRemove'
                          :on-success="uploadSucess"
                          :on-error="uploadError"
                         :file-list="srcList"
                         ref="upload">
                  <el-icon >  + </el-icon>
               </el-upload>
            </el-form-item>

            <el-form-item style="position:relative;margin-top: 50px;" >
               <el-button type="primary" @click="submit('chongzhi')" size="mini" v-loading.fullscreen.lock="fullscreenLoading"
                          style="margin: auto;position: absolute;top:-30px;right: 0;">提交</el-button>
            </el-form-item>

         </el-form>
      </div>

   </div>
</template>

<script>

export default {
  name: "takeRecharge",
   data:function () {
      return{
         //图片
         img:'',
         uploadUrl:'',
         srcList:[],
         //余额
         moneyRemain:'',
         fullscreenLoading:false,
         //表单数据
         ruleForm:{
            moneyChange:'',
            examinerName:'',
            examinerAccount:'',
            pic:'',
         },
         rule:{
            moneyChange:[
               { required: true, message: '请输入充值金额', trigger: 'blur' },
            ],
            examinerName:[
               { required: true, message: '请输入付款账户名称', trigger: 'blur' },
            ],
            examinerAccount:[
               { required: true, message: '请输入支付账户', trigger: 'blur' },
            ]
         }
      }
   },
   mounted() {
      this.uploadUrl = this.$urlUtils.baseUrl+this.$urlUtils.uploadUploadImage;
      //获取余额
      this.getMoneyRemain();
   },
   methods:{
     //充值金额 输入框
      moneyAddInput:function (value) {
        this.ruleForm.moneyChange=value.replace(/^\D*(\d*(?:\.\d{0,3})?).*$/g, '$1')
      },
      //充值账户 输入框
      examimerAccountInput:function (value) {
         this.ruleForm.examinerAccount=value.replace(/^\D*(\d*(?:\.\d{0,0})?).*$/g, '$1')
      },
      //图片上传前处理
      beforeAvatarUpload:function(file){
         const isLt2M = file.size / 1024 / 1024 < 3
         if (!isLt2M) {
            this.$message.error('上传截图大小不能超过 3MB!')
         }
         return isLt2M
      },
      // 有图片移除后 触发
      imgBroadcastRemove (file, fileList) {
         console.log('file',fileList)
         this.srcList = fileList
         this.ruleForm.pic=""
      },
      //上传图片
      uploadFile:function(params){
         console.log('上传图片 params',params)
        /* let fd = new FormData();
         fd.append("name",this.name);
         this.fileList.forEach(item=>{
            //文件信息中raw才是真的文件
            fd.append("files",item.raw);
            //console.log(item.raw)
         })
         this.$axios.post('/uploadUi',fd).then(res=>{
            if (res.data.code === 200) {
               //console.log(res);
               this.$message('上传成功')
            }else{
               this.$message('失败')
            }
         })*/
      },
      //图片上传成功
      uploadSucess:function(item){
         console.log('图片上传成功',item)
         this.ruleForm.pic=item.data;
      },
      //图片上传失败
      uploadError:function(item){
         console.log('图片上传失败',item)
      },
      //获取余额
      getMoneyRemain:function(){
         let account=this.$commonUtils.getSessionData(this.$constant.account)
         let params={
            account:account,
         }
         let that=this
         console.log('getMoneyRemain')
         this.$getRequest(this.$urlUtils.amountGetMoneyRemain,params).then(res => {
            console.log('getMoneyRemain',res)
            that.moneyRemain=res.data==null?'0.00':res.data;
         })
      },
      //提交充值
      submit:function(formName){
         if(formName!==undefined){
            let that=this
            this.$refs[formName].validate((valid, fields) => {
               console.log('fields',fields,that.ruleForm.examinerAccount,that.ruleForm.examinerName);
               if(valid){
                  //充值逻辑
                  if(this.$commonUtils.isEmpty(this.ruleForm.pic)){
                     this.$message.info('请上传支付截图')
                     return
                  }
                  let account=this.$commonUtils.getSessionData(this.$constant.account)
                  let params={
                     account:account,
                     moneyChange:that.ruleForm.moneyChange,
                     examinerName:that.ruleForm.examinerName,
                     examinerAccount:that.ruleForm.examinerAccount,
                     changeType:"2",//2 充值 1短信发送
                     total:1,
                     price:that.ruleForm.moneyChange,
                     pic:that.ruleForm.pic
                  }
                  that.fullscreenLoading=true
                  that.$post(this.$urlUtils.amountAddMoneyRecord,this.$QS.stringify(params)).then(res => {
                     that.fullscreenLoading=false
                     console.log('amountAddMoneyRecord',res)
                     if(res.data.status===200){
                        that.$message.success('充值成功')
                        that.ruleForm.moneyChange=''
                        that.ruleForm.examinerName=''
                        that.ruleForm.examinerAccount=''
                        that.ruleForm.pic=''
                        that.$message.info('充值成功,审核后余额更新!')
                     }

                  })
               }
            })
         }
      }
   }
}
</script>

<style scoped>
   .home{
      background: #ACE1F5;
      width: 100%;
      height: auto;
      margin: auto;
      padding-bottom: 50px;
   ;
   }
   .content{
      width: 500px;
      height: auto;
      background: transparent;
      size: 24px;
      color: #005BAC;
      font-weight: bold;
   }
   .title{
      color: #005BAC;
      size: 48px;
      font-weight: bold;
      margin-left: 20px;
      margin-top: 8px;
   }
   .form{
      background: #EFFFFF;
      width: 100%;
      padding: 10px;
      margin-top: 10px;
   }
   .margin-top-10{
      margin-top: 10px;
   }
   .form >>> .el-form-item__label{
      color: #005BAC;
   }
   .common-txt-gray{
      color: #838484;
      size: 24px;
      font-weight: 500;
      background:white;
      padding: 2px;
   }
   .size{
      size: 16px;
   }


</style>